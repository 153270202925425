import { useEffect } from "react";
import Logo from "../Logo";

export default function Footer() {

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://seal.godaddy.com/getSeal?sealID=rVG0qEUlGydBLipiMJgd9SxP7RhecuVpaJdSoBwFd8KNxb0a7mixSNg1s2dv';

        script.onload = () => {
            console.log('Script loaded successfully');
        };

        script.onerror = (error) => {
            console.error('Error loading script:', error);
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <footer className="bg-slate-600 rounded-md bg-opacity-50 flex flex-col items-center justify-center w-full text-white p-6">
            <div className="mx-auto">
                <Logo />
            </div>
            <div className="max-w-4xl mx-auto text-center">
                <p className="text-xs">
                    &copy; {new Date().getFullYear()} Find My IP Address. All rights reserved.
                </p>
            </div>
            <span className="mt-4" id="siteseal"></span>
        </footer>
    );
}

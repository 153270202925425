import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BlogDetailResponse } from "../../Models/BlogModel";
import { useEffect, useState } from "react";
import LoadingIndicator from "../Pages/LoadingIncator";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


const rest_api_key = process.env.REACT_APP_BLOG_API_KEY;
const getBlog = async (id: string) => {
    const response = await axios.get<BlogDetailResponse>(`https://quintadb.com/apps/aVWPxcLMLcHyontCkQW7y8/dtypes/${id}.json?rest_api_key=${rest_api_key}`);
    const data = await response.data;
    return data;
}

function separateTags(tagString: string) {

    const tagsArray = tagString.trim().split(',');
    const trimmedTags = tagsArray.map(tag => tag.trim());
    return trimmedTags;
}

export default function BlogDetail() {
    const { id } = useParams<{ id: string }>();

    const { data: blogData, isLoading, isFetching, isFetched } = useQuery({
        queryKey: ['blog', id],
        queryFn: () => getBlog(id!),
        staleTime: 300000
    })
    console.log(blogData);
    if (isLoading || isFetching || !isFetched) return <LoadingIndicator message="Getting blog details..." />
    return (
        <div className="lg:mt-36 max-w-6xl mx-auto  mb-12 px-4 w-full">
            <div className="max-w-6xl px-4 py-6 mx-auto bg-gray-50 rounded-md">
                <img
                    className="object-contain w-full h-[60dvh]  shadow-sm rounded-md bg-transparent"
                    src={blogData?.record.values.aGmCorkZTcOilcPcpdRHir}
                    alt="Blog Image"
                />

                <div className="flex flex-wrap justify-start my-4 gap-2">
                    {separateTags(blogData?.record.values.ddOmocWOHdL4TnWOJdMCks!).map((tag, index) => (
                        <p
                            key={index}  // Adding a unique key for each tag
                            className="px-2 py-1 font-semibold text-sm bg-sky-900 text-white rounded-md hover:bg-sky-600 cursor-pointer mr-4"
                        >
                            {tag} 
                        </p>
                    ))}
                </div>

                <div className="mt-2">
                    <h1 className="sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl font-bold">{blogData?.record.values.dcRmkauSncW5ahW45tiLOW}</h1>
                    <div className="font-light text-gray-600">
                        <p className="font-semibold text-lg mt-2">{blogData?.record.values.aEjmoetCnbWPRcVchcRIfn}</p>
                        <p className="flex items-center mt-6 mb-6">
                            <img
                                src="https://i.ibb.co/Wz65D19/1682331186148.jpg"
                                alt="avatar" className="object-cover w-14 h-14 rounded-full mr-4" />
                        <span className="md:font-bold font-medium text-gray-700">By {blogData?.record.added_by} on {formatDateString(blogData?.record.values.ddP8oTEsXcWOBdGeBcO8of!)}</span>
                        </p>
                    </div>
                </div>

                <div className="max-w-6xl mx-auto text-2xl text-gray-700 mt-4  bg-gray-100 rounded-md p-2">
                    <div dangerouslySetInnerHTML={{__html: blogData?.record.values.ddNmk8W4vdTz_dHvf0smkH!}}>
                        
                    </div>
                </div>
            </div>

        </div>

    )

}

function formatDateString(dateString: string): string {
    const dateObject = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    };
    const formattedDate = dateObject.toLocaleDateString('en-US', options);
    return formattedDate;
}


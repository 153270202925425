import { NavLink } from "react-router-dom";
import Logo from "../Logo";

const navItems = [
    {
        name: "Home",
        path: "/",
    },
    {
        name: "What is an IP",
        path: "/whatIsAnIP",
    },
    {
        name: "FAQS",
        path: "/FAQS",
    },
    {
        name: "Blogs",
        path: "/blogs",
    },
]

export default function Header() {
    return (
        <div className="fixed top-6 left-4 right-4 h-16 flex items-center justify-between px-4 z-10">
            <Logo />
            <div className="flex flex-row space-x-6 overflow-hidden">
                {navItems.map((item, index) => (
                    <NavLink
                        key={index}
                        to={item.path}
                        className="md:text-lg font-semibold text-white hover:text-gray-300 transition-all duration-300 md:p-3 rounded-md border border-transparent hover:border-white"
                    >
                        {item.name}
                    </NavLink>
                ))
                }
            </div>
        </div>
    );
}



export default function Info() {
    return (
        <div className="bg-transparent bg-stretch min-h-screen flex items-center justify-center text-white pt-8 lg:px-0 px-2">
            <div className="max-w-4xl p-8 rounded-md shadow-xl bg-slate-700 bg-opacity-50" itemScope itemType="https://schema.org/WebPage">
                <h1 className="text-4xl font-bold mb-6" itemProp="headline">
                    What is an IP Address?
                </h1>
                <p className="text-lg mb-4" itemProp="description">
                    An IP (Internet Protocol) address is a numerical label assigned to each device connected to a computer network that uses the Internet Protocol for communication. It serves two main purposes: host or network interface identification and location addressing. IP addresses are essential for devices to communicate over the Internet.
                </p>
                <h2 className="text-2xl font-bold mb-4" itemProp="headline">Types of IP Addresses:</h2>
                <ul className="list-disc pl-6" itemProp="description">
                    <li className="text-lg mb-2">IPv4 (Internet Protocol version 4)</li>
                    <li className="text-lg mb-2">IPv6 (Internet Protocol version 6)</li>
                </ul>

                <h2 className="text-2xl font-bold mb-4" itemProp="headline">History of IP Addresses:</h2>
                <p className="text-lg mb-4" itemProp="description">
                    The development of IP addresses dates back to the early days of the ARPANET, the precursor to the modern Internet. IPv4, the first widely adopted version, was standardized in 1981.
                </p>

                <h2 className="text-3xl font-bold mb-6 text-white" itemProp="headline">How Machines Are Assigned IP Addresses</h2>
                <p className="text-white mb-4" itemProp="description">
                    The assignment of IP addresses to machines is a crucial aspect of networking that enables communication between devices on a network. IP addresses, which stand for Internet Protocol addresses, serve as unique identifiers for each device connected to a network.
                </p>
                <p className="text-white mb-4" itemProp="description">
                    Here's a breakdown of how machines are assigned IP addresses:
                </p>
                <ol className="list-decimal pl-6 text-white mb-6" itemProp="description">
                    <li className="mb-4" itemProp="suggestedAnswer" itemScope itemType="https://schema.org/Answer">
                        <p itemProp="text">
                            <span className="font-semibold">Dynamic Host Configuration Protocol (DHCP):</span> Many networks use DHCP to automatically assign IP addresses to devices. When a device joins the network, it sends a DHCP request to a DHCP server. The server then dynamically assigns an available IP address to the device, along with other network configuration details.
                        </p>
                    </li>
                    <li className="mb-4" itemProp="suggestedAnswer" itemScope itemType="https://schema.org/Answer">
                        <p itemProp="text">
                            <span className="font-semibold">Static IP Assignment:</span> In some cases, administrators manually assign static IP addresses to machines. This method involves configuring each device with a unique IP address that doesn't change over time. It's common for servers, printers, and network devices to have static IP addresses.
                        </p>
                    </li>
                    <li className="mb-4" itemProp="suggestedAnswer" itemScope itemType="https://schema.org/Answer">
                        <p itemProp="text">
                            <span className="font-semibold">Network Address Translation (NAT):</span> NAT is often used in home or small office networks. A router with NAT capability assigns a single public IP address to the entire network, and devices on the local network are assigned private IP addresses. The router translates requests from internal devices to the single public IP address when communicating over the Internet.
                        </p>
                    </li>
                </ol>
                <p className="text-white pb-4" itemProp="description">
                    Understanding how IP addresses are assigned is essential for maintaining a well-functioning and secure network infrastructure. Whether through DHCP, static assignment, or NAT, the allocation of IP addresses plays a crucial role in facilitating seamless communication across the global network.
                </p>

                <h2 className="text-2xl font-bold mb-4" itemProp="headline">Use Cases:</h2>
                <ul className="list-disc pl-6 mb-4" itemProp="description">
                    <li className="text-lg mb-2" itemProp="description">1. Internet Communication: Enables devices to communicate over the Internet.</li>
                    <li className="text-lg mb-2" itemProp="description">2. Network Routing: Essential for routing data between networks.</li>
                    <li className="text-lg mb-2" itemProp="description">3. Security: Used in security protocols and firewalls to control access.</li>
                    <li className="text-lg mb-2" itemProp="description">4. IoT (Internet of Things): Connects and identifies devices in IoT applications.</li>
                </ul>

                <h2 className="text-2xl font-bold mb-4" itemProp="headline">Common Terminal Commands:</h2>
                <div className="mb-4" itemProp="description">
                    <p className="text-lg font-bold pb-4" itemProp="description">1. Check Your Public IP Address:</p>
                    <code className="bg-gray-800 text-white p-2 rounded-md" itemProp="codeRepository">curl ifconfig.me</code>
                </div>
                <div className="mb-4" itemProp="description">
                    <p className="text-lg font-bold pb-4" itemProp="description">2. Display Network Interfaces:</p>
                    <code className="bg-gray-800 text-white p-2 rounded-md" itemProp="codeRepository">ifconfig</code>
                </div>
                <div className="mb-4" itemProp="description">
                    <p className="text-lg font-bold pb-4" itemProp="description">3. Get Detailed Network Information:</p>
                    <code className="bg-gray-800 text-white p-2 rounded-md" itemProp="codeRepository">ipconfig</code>
                </div>
                <div itemProp="description">
                    <p className="text-lg font-bold pb-4" itemProp="description">4. Find IP Address of a Domain:</p>
                    <code className="bg-gray-800 text-white p-2 rounded-md" itemProp="codeRepository">nslookup example.com</code>
                </div>
            </div>
        </div>
    )
}
import { Link, NavLink } from "react-router-dom";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

interface BlogCardProps {
    id: string;
    writer: string;
    blogTitle: string;
    description: string;
    date: string;
    isPublic?: boolean;
    imageUrl?: string;
}

export default function BlogCard({ id, writer, blogTitle, description, date, imageUrl, isPublic }: BlogCardProps) {
    console.log(id);
    return (

        <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <img className="rounded-t-lg bg-cover bg-white h-80 w-full" src={imageUrl} alt="BlogImage" />
            <div className="p-5">
                <h5 className="mb-2 text-2xl  font-bold tracking-tight text-white">{blogTitle}</h5>
                <h1 className="mb-3 font-normal line-clamp-4" style={{ color: 'white' }}>
                    <span dangerouslySetInnerHTML={{ __html: description}}></span>
                </h1>

                <Link to={`/blogs/blogDetails/${id}`} className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-sky-800 rounded-lg hover:bg-sky-800/20 focus:ring-4 focus:outline-none focus:ring-blue-300 ">
                    Read more
                    <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </Link>
            </div>
        </div>

    )
}



import React from "react";

function IPIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="#000"
      version="1.1"
      viewBox="0 0 20.234 20.234"
      xmlSpace="preserve"
    >
      <path
        fill="#fefefe"
        d="M6.776 4.72h1.549v6.827H6.776V4.72zm4.975-.051c-.942 0-1.61.061-2.087.143v6.735h1.53V9.106c.143.02.324.031.527.031.911 0 1.691-.224 2.218-.721.405-.386.628-.952.628-1.621 0-.668-.295-1.234-.729-1.579-.456-.365-1.136-.547-2.087-.547zm-.042 3.281c-.222 0-.385-.01-.516-.041V5.895c.111-.03.324-.061.639-.061.769 0 1.205.375 1.205 1.002 0 .699-.507 1.114-1.328 1.114zM10.117 0C5.523 0 1.8 3.723 1.8 8.316s8.317 11.918 8.317 11.918 8.317-7.324 8.317-11.917S14.711 0 10.117 0zm.021 13.373a5.523 5.523 0 11.001-11.045 5.523 5.523 0 01-.001 11.045z"
      ></path>
    </svg>
  );
}

export default IPIcon;
import { useQueries, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ApiResponse } from "../../Models/BlogModel";


const base_url = process.env.REACT_APP_BLOGS_GET_URL;
const fetchBlogs = async () => {
    const res = await axios.get<ApiResponse>(base_url!);
    console.log(res);
    return res.data;
}

export const useBlogs = () => {
    return useQuery({
        queryKey: ['blogs'],
        queryFn: fetchBlogs,
        staleTime: 200000
    });
}
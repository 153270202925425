// export default SpeedTest;
export const measureNetworkSpeed = () => {
    return new Promise((resolve, reject) => {
        const imageAddr = "https://upload.wikimedia.org/wikipedia/commons/3/3a/Bloemen_van_adderwortel_%28Persicaria_bistorta%2C_synoniem%2C_Polygonum_bistorta%29_06-06-2021._%28d.j.b%29.jpg";
        const downloadSize = 7300000; // bytes

        let startTime:number, endTime : number;
        const download = new Image();

        download.onload = function () {
            endTime = (new Date()).getTime();
            calculateSpeed();
        };

        download.onerror = function () {
            reject("Invalid image, or error downloading");
        };

        startTime = (new Date()).getTime();
        const cacheBuster = "?nnn=" + startTime;
        download.src = imageAddr + cacheBuster;

        const calculateSpeed = () => {
            const duration = (endTime - startTime) / 1000;
            const bitsLoaded = downloadSize * 8;
            const speedBps = bitsLoaded / duration;
            const speedMbps = (speedBps / 1024 / 1024).toFixed(2);

            resolve(speedMbps);
        };
    });
};



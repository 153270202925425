//@ts-nocheck
import React, { useState, useRef } from 'react';
import useSound from 'use-sound';
import audioFile from '../Assets/audiofile.mp3';
import { CopyButton } from './NetworkInfo';

const GetLocalIP: React.FC = () => {
    const [localIP, setLocalIP] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [copiedLocal, setCopiedLocal] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);


    const handleCopyClick = (text: string, setCopied: React.Dispatch<React.SetStateAction<boolean>>) => {
        setCopied(true);
        navigator.clipboard.writeText(text)
            .then(() => {
                setTimeout(() => {
                    setCopied(false);
                }, 3000);
            })
            .catch((error) => {
                console.error('Unable to copy to clipboard', error);
            });
    };


    const [play, { stop }] = useSound(audioFile);


    const handleGetLocalIP = async () => {
        if (!isPlaying) {
            play();
            setIsPlaying(true)
        }
        try {
            // Request microphone access
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

            const pc = new RTCPeerConnection();
            stream.getTracks().forEach((track) => {
                pc.addTrack(track, stream);
            });

            pc.createOffer()
                .then((offer) => pc.setLocalDescription(offer))
                .catch((offerError) => {
                    setError(`Error creating offer: ${offerError}`);
                    console.error('Error creating offer:', offerError);
                });

            pc.onicecandidate = (event) => {
                if (event.candidate) {
                    const match = event.candidate.candidate.match(/(?:[0-9]{1,3}\.){3}[0-9]{1,3}/);
                    const ipAddress = match ? match[0] : null;
                    setLocalIP(ipAddress);
                    pc.onicecandidate = null;
                    pc.close();
                    play(); // Play the audio clip
                }
            };
        } catch (error) {
            setError(`Error accessing audio: ${error}`);
            console.error('Error accessing audio:', error);
        }
    };

    return (
        <div className="flex flex-col items-center space-x-4 bg-sky-900 rounded-md text-white p-4">
            <p className="text-sm my-2">
                Note: To obtain the local IP address, the application requests microphone access, creates a connection,
                and extracts the IP from ICE candidates generated during the WebRTC process, while playing an audio file for a user-friendly experience.
            </p>

            {error && <p className="text-red-500 my-2">Error: {error}</p>}
            <div className="flex flex-col items-center">
                <div className="flex items-center space-x-4 pt-4">
                    <button className="bg-white px-4 py-2 text-black rounded-md font-semibold" onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleGetLocalIP()}>Play</button>
                    <button className="bg-white px-4 py-2 text-black rounded-md font-semibold" onClick={(e: React.MouseEvent<HTMLButtonElement>) => stop()}>Stop</button>
                </div>
                <div className="flex my-4 font-semibold">
                    {localIP && isPlaying &&
                        <>
                            <p>Your local IP address: {localIP}</p>
                            <CopyButton text={localIP} onCopyClick={() => handleCopyClick(localIP, setCopiedLocal)} copied={copiedLocal} />
                        </>

                    }
                </div>
            </div>
        </div>
    );
};

export default GetLocalIP;

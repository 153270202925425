import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../Logo';

const HamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        console.log('clicked');
        setIsOpen(!isOpen);
    };

    return (
        <div className="relative flex justify-between items-center">
            <button
                className="text-white focus:outline-none z-50 fixed top-4 left-4"
                onClick={toggleMenu}
                aria-label="Toggle Menu"
            >
                <svg
                    className="h-8 w-8 cursor-pointer"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
                    />
                </svg>
            </button>

            {isOpen && (
                <div className="fixed inset-0 bg-blue-950 bg-opacity-50 "></div>
            )}

            <div
                className={`fixed top-0 right-0 bg-[#012B54] h-screen w-64 transform ${isOpen ? 'translate-x-0' : 'translate-x-full'
                    } transition-transform duration-300 ease-in-out p-4`}
            >
                <nav>
                    <ul className="space-y-12 z-50 text-2xl mt-8">
                        <li>
                            <NavLink to="/" className="text-white" onClick={toggleMenu}>Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="/whatIsAnIP" className="text-white" onClick={toggleMenu}>What is an IP</NavLink>
                        </li>
                        <li>
                            <NavLink to="/FAQs" className="text-white" onClick={toggleMenu}>FAQs</NavLink>
                        </li>
                        <li>
                            <NavLink to="/blogs" className="text-white" onClick={toggleMenu}>Blogs</NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default HamburgerMenu;

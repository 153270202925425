import { Route, Routes } from "react-router-dom";
import Info from "../Components/Pages/Info";
import Main from "../Components/Main";
import FAQs from "../Components/Pages/FAQs";
import Blogs from "../Components/Blogs/Blogs";
import BlogDetail from "../Components/Blogs/BlogDetail";
import GetLocalIP from "../Components/GetLocalIP";


export default function AppRoutes() {
    return (
        <div className="overflow-hidden">
            <Routes>
                <Route path="/" element={<Main />}></Route>
                <Route path="/whatIsAnIP" element={<Info />}></Route>
                <Route path="/FAQs" element={<FAQs />}></Route>
                <Route path="/blogs" element={<Blogs />}></Route>
                <Route  path="/blogs/blogDetails/:id" element={<BlogDetail />}></Route>

            </Routes>
        </div>

    )
}
import { useState, useEffect } from 'react';
import Footer from './Components/Header&Footer/Footer';
import Header from './Components/Header&Footer/Header';
import AppRoutes from './Routes/AppRoutes';
import StarfieldAnimation from "react-starfield-animation";
import HamburgerMenu from './Components/Header&Footer/Hamburger';


function App() {
  const [isScreenSmall, setIsScreenSmall] = useState<boolean>(false);

  const originalConsoleLog = console.log;

  console.log = function () {
    if (process.env.NODE_ENV !== 'production') {
      originalConsoleLog.apply(console, arguments as any);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth > 900);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className="flex flex-col justify-center w-screen  relative overflow-hidden">
      <div className="bg-gradient-to-br from-[#00172D] via-[#00498D] to-[#150D30] bg-stretch flex flex-col items-center justify-center z-5 overflow-hidden">
        <StarfieldAnimation
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        />
        <div className="h-10vh w-full z-10 overflow-hidden mb-16 md:mb-32">
          {isScreenSmall ? <Header /> : <HamburgerMenu />}
        </div>
        <div className="min-h-screen w-full z-0 overflow-x-hidden mb-12">
          <AppRoutes />
        </div>
        <div className="flex w-full">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;

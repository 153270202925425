// LoadingIndicator.tsx
import React from 'react';

interface Props{
  message?: string;
}

const LoadingIndicator: React.FC<Props> = ({message}) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center z-50">
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 blur-md"></div>
      <div className="border-t-4  rounded-full animate-spin w-24 h-24 border-sky-700"></div>
      <h1 className="text-2xl text-white mt-6">{message ? message : "Loading..."}</h1>
    </div>
  );
};

export default LoadingIndicator;

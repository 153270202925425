import { GoogleMap, useLoadScript, Marker, Libraries } from '@react-google-maps/api';
import { useEffect, useState } from 'react';


interface Props {
    latitude: number;
    longitude: number;
}

const libraries: Libraries = ['places'];

const mapContainerStyle = {
    width: '100%',
    height: '30vh',
};

const options = {
    disableDefaultUI: true,
    zoomControl: true,
};
const api_key = process.env.REACT_APP_MAP_API_KEY;

export default function MapView({ latitude, longitude }: Props) {
    const [center, setCenter] = useState({ lat: latitude, lng: longitude });

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: api_key!,
        libraries,
    });

    useEffect(() => {
        setCenter({ lat: latitude, lng: longitude });
    }, [latitude, longitude]);

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    return (
        <div>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={10}
                center={center}
            >
                <Marker position={center} />
            </GoogleMap>
        </div>
    );
};

import { useEffect, useState } from "react";
import { LocationInfo } from "../Models/AppModels"
import IPIcon from "./Icons/IPSvg"
import { Network, Gauge, Globe, Locate, LocateFixed } from "lucide-react";
import { measureNetworkSpeed } from "./SpeedTest";
import LoadingIndicator from "./Pages/LoadingIncator";


interface Props {
    data: LocationInfo
}
const getLocalIP = () => {
    const IPdata = document.getElementById("ipAddress");
    const ip = IPdata?.getAttribute('x-data')!;
    console.log('what is my ip: ',ip);
    return ip;
};


export default function NetworkInfo({ data }: Props) {
    const [copiedPublic, setCopiedPublic] = useState(false);
    const [copiedLocal, setCopiedLocal] = useState(false);
    const [localIP, setLocalIP] = useState<string>("");
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [networkSpeed, setNetworkSpeed] = useState<string>("");
    const [userBrowser, setUserBrowser] = useState<string>("");
    const [publicTooltipOpen, setPublicTooltipOpen] = useState(false);
    const [isOnline, setIsOnline] = useState<boolean>(true);

    useEffect(() => {
        const isOnline = navigator.onLine;
        console.log('Online Status:', isOnline);
        setIsOnline(isOnline);
    }, []);


    useEffect(() => {
        setTimeout(() => {
            const ip = getLocalIP();
            setLocalIP(ip);
        }, 1000);

    }, []);


    useEffect(() => {
        const userAgent = navigator.userAgent;
        const isChrome = userAgent.includes("Chrome");
        const isFirefox = userAgent.includes("Firefox");
        const isSafari = userAgent.includes("Safari") && !isChrome;
        const isEdge = userAgent.includes("Edge");
        const isOpera = userAgent.includes("Opera");
        const isIE = userAgent.includes("MSIE") || userAgent.includes("Trident/");

        if (isChrome) {
            setUserBrowser("Chrome");
        } else if (isFirefox) {
            setUserBrowser("Firefox");
        } else if (isSafari) {
            setUserBrowser("Safari");
        } else if (isEdge) {
            setUserBrowser("Edge");
        } else if (isOpera) {
            setUserBrowser("Opera");
        } else if (isIE) {
            setUserBrowser("Internet Explorer");
        } else {
            setUserBrowser("Unknown");
        }
    }, []);



    const handleMouseEnter = () => {
        setTooltipOpen(true);
    };

    const handleMouseLeave = () => {
        setTooltipOpen(false);
    };
    const handleMouseEnterForPublicIP = () => {
        setPublicTooltipOpen(true);
    };

    const handleMouseLeaveForPublicIP = () => {
        setPublicTooltipOpen(false);
    };


    const handleCopyClick = (text: string, setCopied: React.Dispatch<React.SetStateAction<boolean>>) => {
        setCopied(true);
        navigator.clipboard.writeText(text)
            .then(() => {
                setTimeout(() => {
                    setCopied(false);
                }, 3000);
            })
            .catch((error) => {
                console.error('Unable to copy to clipboard', error);
            });
    };

    useEffect(() => {
        const fetchNetworkSpeed = async () => {
            try {
                const speed = await measureNetworkSpeed();
                setNetworkSpeed(speed as string);
            } catch (error) {
                console.error(error);
            }
        };

        fetchNetworkSpeed();
    }, []);


    return (
        <div className="mt-6 p-4 bg-[#012B54] text-white rounded-md bg-opacity-95">
            <p className="font-semibold md:text-lg text-center bg-white rounded-md text-[#012B54]">IP Address & Network Information</p>
            <div className="flex items-center mt-2">
                <p className="font-semibold">Connection Status:</p>
                <div className="flex items-center space-x-2 md:text-lg  ml-auto">
                    <span className={`h-4 w-4 rounded-full ${isOnline ? 'bg-green-600' : 'bg-red-700'}`}></span>
                    <span>{isOnline ? 'Connected' : 'Disconnected'}</span>
                </div>
            </div>
            <div className="flex items-center mt-4">
                <p className="font-semibold">Local IP:</p>
                <div className="flex items-center md:text-lg ml-auto space-x-2 ">
                    <IPIcon />
                    <span className="truncate w-[6ch]">{localIP ? localIP : "Could'nt Find"}</span>
                    <div
                        className="relative"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div className={`absolute top-full right-0 min-w-44 p-2 bg-white border rounded z-10 ${tooltipOpen ? '' : 'hidden'}`}>
                            <p className="text-sm text-black">Your Local IP or Private IP is <strong>{localIP}</strong>. If you see 'guid.local' instead of your actual local IP for eg 192.168.1.0 , it might be due to the 'fetch' function not working correctly on your browser. This is a result of the privacy changes in modern web browsers. </p>
                        </div>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="10" stroke="#fefefe" strokeWidth="2" />
                            <line x1="12" y1="8" x2="12" y2="12" stroke="#fefefe" strokeWidth="2" strokeLinecap="round" />
                            <line x1="12" y1="16" x2="12" y2="12" stroke="#fefefe" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </div>
                </div>
                <CopyButton text={localIP} onCopyClick={() => handleCopyClick(localIP, setCopiedLocal)} copied={copiedLocal} />
            </div>
            <div className="flex items-center mt-2">
                <p className="font-semibold">Public IP:</p>
                <div className="flex items-center space-x-2 md:text-lg  ml-auto">
                    <IPIcon />
                    <span className={`${data.ip.length > 16 ? 'truncate w-[10ch]' : ''}`}>{data.ip}</span>
                </div>
                <div
                    className="relative"
                    onMouseEnter={handleMouseEnterForPublicIP}
                    onMouseLeave={handleMouseLeaveForPublicIP}
                >
                    <div className={`absolute top-full right-0 min-w-44 p-2 bg-white border rounded z-10 ${publicTooltipOpen && data.ip.length < 15 ? '' : 'hidden'}`}>
                        <p className="text-sm text-black">Your public IP is <strong>{data.ip}</strong>. If you see to long public ip for eg: 2401:4800:.. instead of your actual public IP, it might be due to the 'fetch' function not working correctly on your browser. This is a result of the privacy changes in modern web browsers.</p>
                    </div>
                    {data.ip.length > 14 &&
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="10" stroke="#fefefe" strokeWidth="2" />
                            <line x1="12" y1="8" x2="12" y2="12" stroke="#fefefe" strokeWidth="2" strokeLinecap="round" />
                            <line x1="12" y1="16" x2="12" y2="12" stroke="#fefefe" strokeWidth="2" strokeLinecap="round" />
                        </svg>}
                </div>
                <CopyButton text={data.ip} onCopyClick={() => handleCopyClick(data.ip, setCopiedPublic)} copied={copiedPublic} />
            </div>
            <div className="flex items-center mt-2">
                <p className="font-semibold">Network:</p>
                <div className="flex items-center space-x-2 md:text-lg  ml-auto">
                    <Network size={18} color="#ffffff" strokeWidth={1.5} />
                    <span>{data.network}</span>
                </div>
            </div>
            <div className="flex items-center mt-2">
                <p className="font-semibold">Version:</p>
                <div className="md:text-lg  ml-auto">{data.version}</div>
            </div>
            <div className="flex items-center mt-2">
                <p className="font-semibold">Estimated Network Speed:</p>
                <div className="flex items-center space-x-2 md:text-lg  ml-auto">
                    <Gauge size={20} color="#ffffff" strokeWidth={2} />
                    <span>{networkSpeed}</span>
                </div>
            </div>
            <div className="flex items-center mt-2">
                <p className="font-semibold">Browser:</p>
                <div className="flex items-center space-x-2 md:text-lg  ml-auto">
                    <Globe size={18} color="#ffffff" strokeWidth={1.5} />
                    <span>{userBrowser}</span>
                </div>
            </div>
            <div className="flex items-center mt-2">
                <p className="font-semibold">Latitude:</p>
                <div className="flex items-center space-x-2 md:text-lg  ml-auto">
                    <Locate color="#ffffff" />
                    <span>{data.latitude}</span>
                </div>
            </div>
            <div className="flex items-center mt-2">
                <p className="font-semibold">Longitude:</p>
                <div className="flex items-center space-x-2 md:text-lg  ml-auto">
                    <LocateFixed color="#ffffff" />
                    <span>{data.longitude}</span>
                </div>
            </div>
        </div>

    )
}


interface CopyButtonProps {
    text: string;
    onCopyClick: () => void;
    copied: boolean;
}

export const CopyButton: React.FC<CopyButtonProps> = ({ text, onCopyClick, copied }) => (
    <p className="ml-4">
        {copied ? (
            <span>Copied!</span>
        ) : (
            <svg onClick={onCopyClick} xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 1024 1024">
                <g strokeWidth="4" fill="#fefefe">
                    <path d="M768 832a128 128 0 01-128 128H192A128 128 0 0164 832V384a128 128 0 01128-128v64a64 64 0 00-64 64v448a64 64 0 0064 64h448a64 64 0 0064-64h64z"></path>
                    <path d="M384 128a64 64 0 00-64 64v448a64 64 0 0064 64h448a64 64 0 0064-64V192a64 64 0 00-64-64H384zm0-64h448a128 128 0 01128 128v448a128 128 0 01-128 128H384a128 128 0 01-128-128V192A128 128 0 01384 64z"></path>
                </g>
            </svg>
        )}
    </p>
);
import axios from "axios";
import BlogCard from "./BlogCard";
import { useEffect } from "react";
import { useBlogs } from "../Hooks/BlogHooks";



export default function Blogs() {
    const { data: blogs } = useBlogs();

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full mx-auto max-w-6xl mg:mt-0 mt-4 px-4">
            {blogs?.records.map((blog, index) => (
                <BlogCard
                    key={index}
                    id={blog.id}
                    writer={blog.values.bDW5OOybfdSQCnBWtdNHOq}
                    blogTitle={blog.values.dcRmkauSncW5ahW45tiLOW}
                    description={blog.values.ddNmk8W4vdTz_dHvf0smkH}
                    date={blog.values.ddP8oTEsXcWOBdGeBcO8of}
                    imageUrl={blog.values.aGmCorkZTcOilcPcpdRHir ? blog.values.aGmCorkZTcOilcPcpdRHir : ""}
                />
            )
            )}
        </div>
    )
}

import React from 'react';

const NetworkingFAQs = () => {
    return (
        <div className="bg-transparent bg-stretch min-h-screen flex items-center max-w-5xl mx-auto justify-center text-white  mb-12 pt-8 lg:px-0 px-2">
            <div className='bg-slate-700 bg-opacity-50 text-white px-4 rounded-md py-2'>
                <h2 className="text-3xl font-bold mb-6 pt-2">Frequently Asked Questions about Basic Networking</h2>
                <img
                    src="https://i.ibb.co/nwMN8Pg/4103157-1026.jpg"
                    alt="Networking Picture"
                    className="cursor-pointer rounded-md"
                    loading="lazy"
                />

                <a className='text-xs text-gray-400' href="https://www.freepik.com/free-vector/computer-technology-isometric-icon-server-room-digital-device-set-element-design-pc-laptop_4103157.htm#query=computer%20network&position=0&from_view=search&track=ais&uuid=9e1b01c7-ecd7-4949-92c0-db09efc30472">Image by fullvector on Freepik</a>
                <div className="mb-6 mt-6" itemScope itemType="https://schema.org/QAPage">
                    <h3 className="text-2xl font-bold mb-2" itemProp="mainEntity">1. What is Networking?</h3>
                    <p itemProp="description">Networking is a fundamental practice in computer science and technology that involves the connection of computers and other devices to enable the sharing of resources and information. In the context of a computer network, devices are linked together to facilitate communication and data exchange.</p>
                    <p itemProp="description">The primary goal of networking is to establish a seamless and efficient communication channel between devices, allowing them to transmit data, share files, and access shared resources. Networks can vary in size and scope, ranging from local area networks (LANs) within a single building to wide area networks (WANs) that span across large geographical areas.</p>
                    <p itemProp="description">Networking utilizes various technologies and protocols to ensure reliable and secure data transmission. Common networking components include routers, switches, and cables, while communication is often governed by protocols like TCP/IP (Transmission Control Protocol/Internet Protocol). Networking plays a crucial role in enabling the internet, intranets, and various online services that we rely on daily.</p>
                </div>


                <div className="mb-6" itemScope itemType="https://schema.org/QAPage">
                    <h3 className="text-2xl font-bold mb-2" itemProp="mainEntity">2. What are the Main Parts of a Network?</h3>

                    <p itemProp="description">A computer network is composed of several key components that work together to enable communication and data exchange. The main parts of a network include:</p>
                    <ul>
                        <li itemProp="suggestedAnswer" itemScope itemType="https://schema.org/Answer">
                            <p itemProp="text"><strong>Devices:</strong>  These are the fundamental building blocks of a network and can include computers, routers, switches, and other hardware that connect to the network.</p>
                        </li>
                        <li itemProp="suggestedAnswer" itemScope itemType="https://schema.org/Answer">
                            <p itemProp="text"><strong>Communication Protocols:</strong> Networks rely on communication protocols, which are a set of rules governing how data is transmitted and received. Common protocols include TCP/IP (Transmission Control Protocol/Internet Protocol).</p>
                        </li>
                        <li itemProp="suggestedAnswer" itemScope itemType="https://schema.org/Answer">
                            <p itemProp="text"><strong>Physical Infrastructure:</strong> This encompasses the tangible elements that physically connect devices, such as cables and routers. The physical infrastructure ensures the actual transmission of data between devices.</p>
                        </li>
                    </ul>
                </div>



                <div className="mb-6" itemScope itemType="https://schema.org/QAPage">

                    <h3 className="text-2xl font-bold mb-2" itemProp="mainEntity">3. How Does Networking Work?</h3>

                    <p itemProp="description">Networking is a complex process that facilitates the exchange of data between devices. The fundamental workings of networking can be explained through the following key points:</p>

                    <ul>
                        <li itemProp="suggestedAnswer" itemScope itemType="https://schema.org/Answer">
                            <p itemProp="text"><strong>Transmission of Data Packets:</strong> Networking involves the transmission of data packets, which are small units of data. These packets contain information and are sent between devices over the network.</p>
                        </li>

                        <li itemProp="suggestedAnswer" itemScope itemType="https://schema.org/Answer">
                            <p itemProp="text"><strong>Device Communication:</strong> Devices communicate with each other using communication protocols. These protocols define the rules and conventions for data transmission, ensuring that devices can understand and interpret the information they receive.</p>
                        </li>

                        <li itemProp="suggestedAnswer" itemScope itemType="https://schema.org/Answer">
                            <p itemProp="text"><strong>Networking Standards:</strong> Networking relies on established standards to ensure compatibility and interoperability between different devices and systems. Standards such as TCP/IP and Ethernet play a crucial role in defining how devices connect and communicate.</p>
                        </li>

                        <li itemProp="suggestedAnswer" itemScope itemType="https://schema.org/Answer">
                            <p itemProp="text"><strong>Seamless Data Exchange:</strong> The ultimate goal of networking is to enable seamless data exchange between devices. Whether it's sending emails, streaming videos, or accessing web pages, networking allows devices to communicate effectively, following the predefined protocols and standards.</p>
                        </li>
                    </ul>
                </div>

                <div className="mb-6" itemScope itemType="https://schema.org/QAPage">
                    <h3 className="text-2xl font-bold mb-2" itemProp="mainEntity">5. What is DHCP?</h3>
                    <p itemProp="description">Dynamic Host Configuration Protocol (DHCP) is a fundamental component of computer networking. It serves as an automated method for assigning IP addresses to devices within a network, eliminating the need for manual configuration.</p>

                    <p itemProp="description">DHCP plays a crucial role in simplifying the process of network setup. When a device connects to a network, DHCP automatically provides it with a unique IP address, subnet mask, gateway, and other necessary network configuration parameters. This dynamic allocation of IP addresses ensures efficient use of available addresses and facilitates seamless communication between devices.</p>

                    <p itemProp="description">By automating IP address assignment, DHCP enhances network scalability and reduces the risk of address conflicts. It centralizes the management of IP addresses, making it easier for administrators to oversee and control the network's addressing scheme.</p>

                    <p itemProp="description">Additionally, DHCP supports the leasing mechanism, allowing devices to use assigned IP addresses for a specific period. This leasing approach enables better resource utilization and flexibility in network management.</p>

                    <p itemProp="description">In summary, DHCP streamlines the configuration of IP addresses in a network, promoting efficiency, scalability, and simplified network administration.</p>
                </div>

                <div className="mb-6" itemScope itemType="https://schema.org/QAPage">
                    <h3 className="text-2xl font-bold mb-2" itemProp="mainEntity">6. What is a Firewall?</h3>
                    <p itemProp="description">A firewall serves as a crucial network security device, acting as a barrier between a trusted internal network and untrusted external networks. It plays a pivotal role in monitoring and controlling both incoming and outgoing network traffic based on predetermined security rules.

                        Firewalls are designed to prevent unauthorized access to or from private networks. They establish a security perimeter by carefully inspecting data packets and determining whether to allow or block them based on configured rules. This proactive approach helps address implicit security pitfalls and safeguard sensitive information.

                        There are various types of firewalls, including hardware firewalls and software firewalls. Hardware firewalls are typically implemented at the network's entry point, providing a first line of defense. On the other hand, software firewalls run on individual devices, offering an additional layer of protection.

                        In addition to traditional packet filtering, modern firewalls may employ advanced technologies such as stateful inspection, proxy services, and intrusion detection systems. These features enhance the firewall's ability to detect and respond to cyber threats, making them essential components of a comprehensive network security strategy.

                        In summary, a firewall is a critical element of network security, serving as a protective barrier that ensures the integrity and confidentiality of data by controlling and monitoring network traffic


                    </p>
                </div>

                <div className="mb-6" itemScope itemType="https://schema.org/QAPage">
                    <h3 className="text-2xl font-bold mb-2" itemProp="mainEntity">7. What is DNS?</h3>
                    <p itemProp="description">DNS (Domain Name System) is a fundamental system that facilitates the translation of human-readable domain names into numerical IP addresses. This translation is essential for enabling users to access websites and other online resources using easy-to-remember domain names instead of numerical IP addresses.</p>

                    <p itemProp="description">In the context of the internet, every device connected to the network is assigned a unique IP address. While computers and servers communicate using these numerical addresses, humans find it more convenient to use domain names, such as www.example.com, to identify websites.</p>

                    <p itemProp="description">DNS acts as a distributed and hierarchical database, providing a decentralized method for translating domain names into IP addresses. When a user enters a domain name into a web browser, the DNS system is queried to find the corresponding IP address. This process involves multiple DNS servers working collaboratively to provide the correct address.</p>

                    <p itemProp="description">The DNS hierarchy includes various components, such as top-level domains (TLDs), domain registrars, authoritative name servers, and recursive resolvers. These components work together to ensure the accuracy and efficiency of the DNS translation process.</p>

                    <p itemProp="description">DNS is a cornerstone of internet functionality, making it possible for users to navigate the web seamlessly. It plays a vital role in translating user-friendly domain names into the numerical language that networks and devices understand.</p>
                </div>

                <div className="mb-6" itemScope itemType="https://schema.org/QAPage">
                    <h3 className="text-2xl font-bold mb-2" itemProp="mainEntity">8. What is Bandwidth?</h3>
                    <p itemProp="description" className='mb-2'>Bandwidth signifies the maximum rate of data transfer across a network, measuring its capacity to transmit data, usually denoted in bits per second (bps) or megabits per second (Mbps).
                        In simpler terms, think of bandwidth as the width of a pipe – the wider the pipe, the greater the amount of data it can handle simultaneously. It plays a crucial role in determining the speed and efficiency of data transmission, influencing how swiftly data can be sent and received over a network connection.
                        To locally assess bandwidth speed, you can utilize the following command within the command prompt (CMD)
                    </p>
                    <code className="bg-gray-800 text-white p-2 rounded-md" itemProp="codeRepository ">
                        speedtest-cli
                    </code>
                    <p className='mt-2'>
                        Ensure that speedtest-cli is installed globally, and executing this command will furnish details about your network's upload and download speeds, offering valuable insights into the bandwidth capabilities of your connection.
                    </p>
                </div>

                <div className="mb-6" itemScope itemType="https://schema.org/QAPage">
                    <h3 className="text-2xl font-bold mb-2" itemProp="mainEntity">9. What is a Router?</h3>
                    <article>
                        <h1>The Crucial Role of Routers in Networking</h1>
                        <p>
                            Routers, critical networking devices, play a pivotal role in efficiently forwarding data packets between computer networks. Operating at the network level (Level 3) of the OSI model, routers serve as central hubs, connecting various networks and directing data traffic intelligently.
                        </p>
                        <h2>Key Functions of a Router</h2>
                        <ul>
                            <li><strong>Forwarding:</strong> Routers analyze incoming data packets, forwarding them to the appropriate destination based on the destination IP address.</li>
                            <li><strong>Routing Table:</strong> Maintaining a routing table, routers make informed decisions about the best path for data transmission by understanding the network's topology.</li>
                            <li><strong>Network Address Translation (NAT):</strong> Routers utilize NAT to map private IP addresses within a local network to a single public IP address for external communication.</li>
                            <li><strong>Security:</strong> Routers often feature firewall capabilities, filtering and controlling incoming and outgoing network traffic to enhance overall network security.</li>
                        </ul>
                        <p>
                            Routers are essential components in network architecture, ensuring efficient communication between devices in different subnets. Understanding their key functions is crucial for optimizing network performance and security.
                        </p>
                    </article>
                </div>
                <div className="mb-6" itemScope itemType="https://schema.org/QAPage">
                    <h3 className="text-2xl font-bold mb-2" itemProp="mainEntity">10. What is a Subnet?</h3>
                    <article>
                        <h1>Understanding Subnets in IP Networking</h1>
                        <p>
                            Subnets play a fundamental role in IP networking, providing an organized and efficient method for managing IP addresses. By grouping devices based on their IP characteristics, subnets contribute to a well-structured network architecture.
                        </p>
                        <h2 className='font-semibold py-4'>Key Aspects of Subnets: </h2>
                        <ul>
                            <li><strong>Address Space:</strong> Subnets segment a larger IP network into smaller, uniquely addressed spaces, enhancing IP address allocation and management.</li>
                            <li><strong>Device Grouping:</strong> Devices within a subnet share a common network address prefix, typically organized by characteristics such as geographic location, department, or function.</li>
                            <li><strong>Improved Network Performance:</strong> Subnets control and manage network traffic, allowing devices within the same subnet to communicate directly, thus enhancing overall network performance.</li>
                            <li><strong>Enhanced Security:</strong> Logical separation into subnets enables the application of security policies at the subnet level, adding an extra layer of network security.</li>
                            <li><strong>Routing:</strong> Routers play a crucial role in interconnecting subnets, facilitating communication between devices in different subnets.</li>
                        </ul>
                        <p>
                            Subnetting is a foundational concept in IP networking, optimizing IP address utilization and enabling scalable network design.
                        </p>
                    </article>
                </div>

            </div>
        </div >
    );
};

export default NetworkingFAQs;

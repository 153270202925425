import React, { useState } from 'react';
import Modal from 'react-modal';
import GetLocalIP from './GetLocalIP';


Modal.setAppElement('#root');

const GetLocalIPModal: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className='flex flex-col justify-center items-center'>
            <p>Discover an Alternate Method to Find Your Local IP. </p>
            <button onClick={openModal} className='bg-white rounded-md px-4 py-2 max-w-xs text-[#012B54] my-4 hover:underline'>
                Click Here.
            </button>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        maxWidth: '420px',
                        height: '460px',
                        margin: 'auto',
                    },
                }}
            >
                <GetLocalIP />
                <button className='bg-[#012B54] rounded-md text-white absolute bottom-4 px-6 py-2' onClick={closeModal}>Close</button>
            </Modal>
        </div>
    );
};

export default GetLocalIPModal;

import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ApiResponse, LocationInfo } from "../Models/AppModels";
import LoadingIndicator from "./Pages/LoadingIncator";
import IPIcon from "./Icons/IPSvg";
import { Gauge, Globe, Locate, LocateFixed, Network } from "lucide-react";
import { measureNetworkSpeed } from "./SpeedTest";
import MapView from "./Map/MapView";
import NetworkInfo from "./NetworkInfo";
import GetLocalIP from "./GetLocalIP";
import GetLocalIPModal from "./GetIPModal";



const fetchIpInfo = async () => {
    const response = axios.get<LocationInfo>('https://ipapi.co/json/').then((response) => { return response });
    return response;
}


interface WindowSize {
    width: number;
    height: number;
}


export default function Main() {
    const [data, setData] = useState<LocationInfo>({} as LocationInfo);
    const [loading, setLoading] = useState(true);

    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    useEffect(() => {
        fetchIpInfo().then((response) => {
            setData(response.data);
        })

    }, [])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [])

    if (loading) {
        return <LoadingIndicator />
    }

    return (
        <div className="flex flex-col items-center justify-center p-4 md:p-0 min-h-screen">
            <div className="flex flex-col items-center justify-center w-full bg-opacity-0 ">
                <div className="bg-white bg-opacity-75 p-4 rounded-md shadow-md sm:w-2/3 md:w-1/2 lg:w-1/3 h-full">
                    <h1 className="text-3xl font-bold mb-6 text-[#012B54]">{data.country_name}</h1>
                    <NetworkInfo data={data} />
                    <div className="grid grid-cols-2 gap-4 mt-4 px-4">
                        <div>
                            <p className="mb-2">
                                <span className="font-semibold">City:</span> {data.city}
                            </p>
                            <p className="mb-2">
                                <span className="font-semibold">Region:</span> {data.region}
                            </p>
                            <p className="mb-2">
                                <span className="font-semibold">Country Code:</span> {data.country_code}
                            </p>
                        </div>
                        <div>
                            <p className="mb-2">
                                <span className="font-semibold">Calling Code:</span> {data.country_calling_code}
                            </p>
                            <p className="mb-2 md:whitespace-nowrap">
                                <span className="font-semibold">Timezone:</span> {data.timezone}
                            </p>
                            <p className="mb-2">
                                <span className="font-semibold">Org:</span> {data.org}
                            </p>
                        </div>
                    </div>
                    <div className="bg-[#012B54] rounded-md text-white py-2 font-semibold px-4">
                        <GetLocalIPModal />
                    </div>
                    <div className="w-full my-4 rounded-md">
                        <MapView latitude={data.latitude} longitude={data.longitude} />
                    </div>
                </div>
            </div>
        </div>
    );
}


